:root {
    --primary: #B8C5C9;     /* Light blue-gray from wall */
    --secondary: #1B3C59;   /* Dark blue */
    --accent: #40B4B8;      /* Teal baseboard color */
    --dark: #1c1c1c;        /* Black for backgrounds */
    --dark-transparent: rgba(28, 28, 28, 0.95);
    --blue: #40e0d0;
    --yellow: #ff8800;
    --gray: #1c1c1c;
    --gray-transparent: rgba(12, 12, 12, 0.95);
    --navy: #40e0d0;  /* Darker blue for body text */
    --orange: #ff6b35;  /* Orange for menu hover */
    --slide-distance: 100px;
    --slide-time: 1s;
}

/* Global font styles - update the selectors to exclude time/day text */
h1, h2, h3, h4, h5, h6,
.quote p,
.quote cite,
.info h3,
.hours h3, /* Only target the "HOURS" heading */
.order-online a,
.coming-soon p,
.nav-links a,
.mobile-logo,
.menu-section h3,
.menu-item h4,
.menu-notes p,
.price-header span,
.price-columns span,
.menu-item p,
.special-note p,
.loading,
.page-controls span,
.page-controls button,
.dessert-nav-button,
.pastries-button,
.error,
.catering-form label,
.submit-button,
.package h3,
.choice-button,
.add-to-order-button,
.selected-items h3,
.remove-item-button {
    font-family: 'Cinzel', serif !important;
}

/* Keep Roboto for specific elements */
.container .info .location p.address,
.container .info .location p.email,
.hours p { /* Add hours paragraphs to use default font */
    font-family: 'Roboto', Arial, sans-serif !important;
    text-transform: none !important;
    font-size: 1rem !important;
    letter-spacing: 0 !important;
    font-weight: 400 !important;
}

/* Keep email lowercase */
.container .info .location p.email {
    text-transform: lowercase !important;
}

/* Properly capitalize address */
.container .info .location p.address {
    text-transform: capitalize !important;
}

html, body {
    min-height: 100vh;
    margin: 0;
    padding: 0;
    overflow-x: hidden;
    position: relative;
    width: 100%;
    -webkit-overflow-scrolling: touch;
}

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

body {
    background-color: var(--dark);
    color: var(--primary);
    line-height: 1.6;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.container {
    position: relative;
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 1rem;
    text-align: center;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding-top: 4rem;
    gap: 1.5rem;
    align-items: center;
    z-index: 1;
    width: 100%;
    max-width: 100vw;
    overflow-x: hidden;
}

header {
    margin-bottom: 1.5rem;
    margin-top: 1.5rem;
}

/* Keep the main h1 styles here as the single source of truth */
h1 {
    font-size: 5rem;
    margin-bottom: 0.5rem;
    letter-spacing: 0.2em;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
    color: var(--accent);
}

/* Mobile styles */
@media (max-width: 768px) {
    h1 {
        font-size: 5rem;
        letter-spacing: 0.1em;
    }
}

/* Small screen breakpoint */
@media (max-width: 400px) {
    h1, .mobile-logo {
        font-size: 2.5rem !important;
        letter-spacing: 0.05em !important;
        white-space: nowrap !important;
    }

    .greek-a {
        font-size: 1em !important;
    }
}

.greek-a {
    font-size: 1.1em;
    color: var(--accent);
}

/* Update h2 styles to use var(--accent) */
h2 {
    font-size: 2rem;
    letter-spacing: 0.5em;
    border-top: 2px solid var(--accent);
    border-bottom: 2px solid var(--accent);
    padding: 1rem 0;
    margin: 1rem auto;
    max-width: 600px;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
    color: var(--accent); /* Ensure consistent color */
}

/* Update mobile styles to maintain color */
@media (max-width: 768px) {
    h2 {
        font-size: 1.5rem;
        letter-spacing: 0.3em;
        color: var(--accent); /* Maintain color in mobile */
    }
}

@media (max-width: 450px) {
    h2 {
        font-size: 0.9rem !important;
        letter-spacing: 0.1em !important;
        margin: 0.3rem 0 !important;
        color: var(--accent) !important; /* Force color in smallest screens */
    }
}

.quote {
    position: relative;
    max-width: 800px;
    margin: 0 auto 1.5rem;
    font-style: italic;
    padding: 2rem;
    border-left: 4px solid var(--accent);
    border-right: 4px solid var(--accent);
    background-color: var(--gray-transparent);
    backdrop-filter: blur(5px);
    border-radius: 8px;
    margin: 0.5rem 0;
    animation: slideInFromLeft var(--slide-time) ease-out forwards;
}

.quote p {
    font-size: 1.2rem;
    margin-bottom: 1rem;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
}

.quote cite {
    font-size: 1rem;
    display: block;
    text-align: right;
    color: var(--accent);
}

.info {
    position: relative;
    display: flex;
    justify-content: center;
    gap: 4rem;
    margin-bottom: 1.5rem;
    background-color: var(--gray-transparent);
    backdrop-filter: blur(5px);
    border-radius: 8px;
    padding: 2rem;
    margin: 0.5rem 0 1.5rem;
    animation: slideInFromRight var(--slide-time) ease-out forwards;
}

.location, .hours {
    text-align: center;
}

h3 {
    font-size: 1.5rem;
    margin-bottom: 1rem;
    letter-spacing: 0.2em;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
}

.coming-soon {
    position: relative;
    font-size: 2rem;
    letter-spacing: 0.3em;
    margin-top: 1.5rem;
    padding: 2rem;
    border-top: 2px solid var(--accent);
    border-bottom: 2px solid var(--accent);
    background-color: var(--gray-transparent);
    backdrop-filter: blur(5px);
    border-radius: 8px;
    margin: 0.5rem 0;
    animation: slideInFromRight var(--slide-time) ease-out forwards;
}

.menu-decorations {
    position: fixed;
    display: flex;
    flex-direction: column;
    justify-content: center;
    color: var(--navy);
    font-family: 'Cinzel', serif;
    font-size: 1.4rem;
    line-height: 2.5;
    pointer-events: none;
    background-color: var(--gray-transparent);
    backdrop-filter: blur(5px);
    border-radius: 8px;
    padding: 1rem 2rem;
    top: 50%;
    transform: translateY(-50%);
    animation: slideInFromLeft var(--slide-time) ease-out forwards;
}

.menu-left {
    left: 10rem;
    text-align: right;
    padding-right: 2rem;
    border-right: 2px solid var(--accent);
    margin-right: 1rem;
    animation: slideInFromTop var(--slide-time) ease-out forwards;
}

.menu-right {
    right: 10rem;
    text-align: left;
    padding-left: 2rem;
    border-left: 2px solid var(--accent);
    margin-left: 1rem;
    animation: slideInFromBottom var(--slide-time) ease-out forwards;
}

@media (max-width: 1400px) {
    .menu-decorations {
        font-size: 1.2rem;
        padding: 0.8rem 1.5rem;
    }
    .menu-left {
        padding-right: 1rem;
    }
    .menu-right {
        padding-left: 1rem;
    }
}

@media (max-width: 1200px) {
    .menu-decorations {
        display: none;
    }
}

@media (max-width: 768px) {
    .container {
        padding-top: 70px; /* Increase padding to account for nav */
        gap: 1rem;
    }
    
    .quote {
        padding: 1rem;
        margin-bottom: 1rem;
    }
    
    .quote p {
        font-size: 1rem;
    }
    
    .info {
        flex-direction: column;
        gap: 1.5rem;
        margin: 0.5rem 1rem 1rem;
        padding: 1.5rem;
    }
    
    .catering-promo {
        margin: 0.5rem 1rem 1.5rem;
    }
    
    .coming-soon {
        font-size: 1.5rem;
        letter-spacing: 0.2em;
        margin: 0.5rem 1rem;
        padding: 1.5rem;
    }

    .order-online {
        margin: 1rem auto;
    }

    .container header {
        padding-top: 20px; /* Adjust header padding */
        margin-top: 0;
        position: relative;
        z-index: 1; /* Ensure header is above background */
    }

    .responsive-nav {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        width: 100%;
        z-index: 1000; /* Higher z-index to stay on top */
        background-color: var(--dark-transparent);
        backdrop-filter: blur(5px);
        height: 8%;
    }

    .nav-header {
        padding: 0.5rem 1rem;
        width: 100%;
        box-sizing: border-box;
    }

    .mobile-logo {
        font-size: 1.5rem;
        margin: 0;
        padding: 0.5rem 0;
    }

    /* Ensure the Greek delta is fully visible */
    .greek-a {
        display: inline-block;
        position: relative;
        z-index: 2;
    }
}

@media (max-width: 480px) {
    h1 {
        font-size: 2.5rem;
    }
    
    h2 {
        font-size: 1.2rem;
        letter-spacing: 0.2em;
    }
    
    .quote {
        border-left: 2px solid var(--accent);
        border-right: 2px solid var(--accent);
    }
    
    h3 {
        font-size: 1.2rem;
    }
}

/* Add smooth scrolling for better user experience */
html {
    scroll-behavior: smooth;
}

/* Ensure images don't overflow their containers */
img {
    max-width: 100%;
    height: auto;
}

.order-online {
    position: relative;
    margin: 2rem auto 1.5rem;
    animation: slideInFromLeft var(--slide-time) ease-out forwards;
    text-align: center;
}

.order-online a {
    display: inline-block;
    padding: 1.5rem 4rem;
    font-size: 1.8rem;
    font-weight: 600;
    letter-spacing: 0.2em;
    color: var(--dark);
    background-color: var(--accent);
    text-decoration: none;
    border: 2px solid var(--accent);
    transition: all 0.3s ease;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.order-online a:hover {
    background-color: #2eb0a0;
    transform: translateY(-2px);
    box-shadow: 0 8px 16px rgba(64, 224, 208, 0.4);
}

@media (max-width: 768px) {
    .order-online {
        margin: 1rem auto;
    }
    
    .order-online a {
        padding: 1.2rem 3rem;
        font-size: 1.5rem;
    }
}

/* Add a semi-transparent overlay to content sections */
.quote,
.info,
.order-online,
.coming-soon {
    position: relative;
    backdrop-filter: blur(5px);
    border-radius: 8px;
    padding: 2rem;
    margin: 1rem 0;
}

/* Optional: Add a subtle text shadow to improve readability */
h1, h2, h3, p {
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
}

@keyframes slideInFromLeft {
    0% {
        transform: translateX(calc(-1 * var(--slide-distance)));
        opacity: 0;
    }
    100% {
        transform: translateX(0);
        opacity: 1;
    }
}

@keyframes slideInFromRight {
    0% {
        transform: translateX(var(--slide-distance));
        opacity: 0;
    }
    100% {
        transform: translateX(0);
        opacity: 1;
    }
}

@keyframes slideInFromTop {
    0% {
        transform: translate(0, calc(-1 * var(--slide-distance)));
        opacity: 0;
    }
    100% {
        transform: translate(0, -50%);
        opacity: 1;
    }
}

@keyframes slideInFromBottom {
    0% {
        transform: translateY(var(--slide-distance));
        opacity: 0;
    }
    100% {
        transform: translateY(0);
        opacity: 1;
    }
}

.subtitle {
    color: var(--accent);
    font-size: 1.5rem;
    margin-top: 1rem;
    letter-spacing: 0.2em;
    animation: slideInFromRight var(--slide-time) ease-out forwards;
}

/* Add base styles for animated elements to ensure they're hidden initially */
.subtitle,
.quote,
.info,
.order-online,
.coming-soon,
.menu-decorations {
    opacity: 0;
    animation-fill-mode: forwards;
}

/* Update staggered delays */
.subtitle { animation-delay: 0.2s; }
.quote { animation-delay: 0.4s; }
.order-online { animation-delay: 0.6s; }
.info { animation-delay: 0.7s; }
.catering-promo { animation-delay: 0.8s; }
.coming-soon { animation-delay: 0.9s; }
.menu-left { animation-delay: 1s; }
.menu-right { animation-delay: 1s; }

@media (prefers-reduced-motion: reduce) {
    :root {
        --slide-time: 0s;
    }
}

/* About page styles */
.about-section {
    display: flex;
    flex-direction: column;
    max-width: 800px;
    margin: 0 auto;
    opacity: 0;
    animation: fadeIn var(--slide-time) ease-out forwards;
    animation-delay: 0.4s;
    padding: 2rem;
    background-color: var(--gray-transparent);
    backdrop-filter: blur(5px);
    border-radius: 8px;
    border-left: 4px solid var(--accent);
    border-right: 4px solid var(--accent);
}

.about-content {
    text-align: left;
    color: var(--primary);
    font-size: 1.1rem;
    line-height: 1.8;
}

.about-content p {
    margin-bottom: 1rem;
}

.about-block blockquote {
    font-style: italic;
    margin: 2rem 0;
    padding-left: 1rem;
    border-left: 2px solid var(--accent);
    color: #f8f8f8;
    font-size: 1.2rem;
    font-weight: 500;
}

.article-link {
    display: inline-block;
    color: var(--accent);
    text-decoration: none;
    font-weight: bold;
    transition: color 0.3s ease;
    margin-top: 1rem;
}

.article-link:hover {
    color: var(--secondary);
}

@keyframes fadeIn {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

/* Navigation styles */
.nav-container {
    min-height: 100vh;
}

nav {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    padding: 1rem;
    background-color: var(--gray-transparent);
    backdrop-filter: blur(5px);
    z-index: 100;
    display: flex;
    justify-content: center;
    gap: 2rem;
}

nav a {
    color: var(--accent);
    text-decoration: none;
    font-size: 1.2rem;
    font-weight: bold;
    transition: color 0.3s ease;
}

nav a:hover {
    color: var(--secondary);
}

/* Adjust container padding for pages with nav */
.nav-container .container {
    padding-top: 6rem;
}

/* Menu page styles */
.menu-viewer {
    position: relative;
    background-color: var(--gray-transparent);
    backdrop-filter: blur(5px);
    border-radius: 8px;
    padding: 2rem 2rem 4rem 2rem;
    margin: 1rem auto;
    max-width: 1000px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-left: 4px solid var(--accent);
    border-right: 4px solid var(--accent);
    opacity: 0;
    animation: fadeIn var(--slide-time) ease-out forwards;
    animation-delay: 0.4s;
    z-index: 1;
    min-height: 100vh;
    height: auto;
    overflow: visible;
}

.pdf-page {
    width: 100% !important;
    height: auto !important;
    max-width: none !important;
    max-height: none !important;
    background: transparent !important;
}

.react-pdf__Document {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    background: transparent;
}

.react-pdf__Page {
    box-shadow: none !important;
    margin: 1rem 0 !important;
    background: transparent !important;
    width: 100% !important;
}

.react-pdf__Page canvas {
    max-width: 100% !important;
    width: 100% !important;
    height: auto !important;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
}

.page-controls {
    position: absolute;
    bottom: 1rem;
    left: 50%;
    transform: translateX(-50%);
    background: rgba(12, 12, 12, 0.95);
    backdrop-filter: blur(5px);
    padding: 1rem 2rem;
    border-radius: 8px;
    z-index: 1000;
    display: flex;
    align-items: center;
    gap: 3rem;
    border: 1px solid var(--accent);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
    margin-top: 1rem;
}

.page-controls button {
    background-color: transparent;
    color: var(--accent);
    border: 2px solid var(--accent);
    padding: 0.5rem 1rem;
    border-radius: 4px;
    cursor: pointer;
    transition: all 0.3s ease;
    font-size: 1.2rem;
    min-width: 50px;
}

.page-controls button:hover:not(:disabled) {
    background-color: var(--accent);
    color: var(--dark);
}

.page-controls button:disabled {
    opacity: 0.5;
    cursor: not-allowed;
    border-color: rgba(64, 224, 208, 0.3);
}

.page-controls span {
    color: var(--accent);
    font-weight: bold;
    min-width: 120px;
    text-align: center;
}

.loading, .error {
    text-align: center;
    padding: 20px;
    color: var(--accent);
    font-family: 'Cinzel', serif;
}

/* Add these new styles for the catering form */
.catering-form-container {
    max-width: 800px;
    width: 100%;
    margin: 2rem auto;
    padding: 2rem;
    background-color: var(--dark-transparent);
    backdrop-filter: blur(5px);
    border-radius: 8px;
    border: 2px solid var(--accent);
    box-shadow: 0 0 20px rgba(64, 224, 208, 0.1);
    animation: slideInFromLeft var(--slide-time) ease-out forwards;
}

.catering-form {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
}

.form-group {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
}

.catering-form input,
.catering-form select,
.catering-form textarea {
    width: 100%;
    padding: 0.8rem;
    border: 2px solid var(--accent);
    background-color: var(--dark);
    color: var(--primary);
    border-radius: 4px;
    font-family: 'Cinzel', serif;
    transition: all 0.3s ease;
}

.catering-form input::placeholder,
.catering-form textarea::placeholder {
    color: var(--accent);
    opacity: 0.8;
}

.catering-form select {
    appearance: none;
    background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='none' stroke='%2340e0d0' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3e%3cpolyline points='6 9 12 15 18 9'%3e%3c/polyline%3e%3c/svg%3e");
    background-repeat: no-repeat;
    background-position: right 1rem center;
    background-size: 1em;
    padding-right: 2.5rem;
}

.catering-form select option {
    background-color: var(--dark);
    color: var(--accent);
}

.catering-form input:focus,
.catering-form select:focus,
.catering-form textarea:focus {
    outline: none;
    border-color: var(--secondary);
    background-color: var(--dark);
}

.submit-button {
    background-color: var(--accent);
    color: var(--dark);
    border: 2px solid var(--accent);
    text-transform: uppercase;
    letter-spacing: 0.2em;
    font-weight: bold;
    padding: 1rem 2rem;
    border-radius: 4px;
    cursor: pointer;
    transition: all 0.3s ease;
}

.submit-button:hover {
    background-color: var(--secondary);
    color: var(--primary);
    border-color: var(--secondary);
}

.form-message {
    padding: 1rem;
    border-radius: 4px;
    text-align: center;
    margin-top: 1rem;
}

.form-message.success {
    background-color: rgba(0, 255, 0, 0.1);
    color: #00ff00;
    border: 1px solid #00ff00;
}

.form-message.error {
    background-color: rgba(255, 0, 0, 0.1);
    color: #ff0000;
    border: 1px solid #ff0000;
}

@media (max-width: 768px) {
    .catering-form-container {
        padding: 1rem;
        margin: 1rem;
    }
    
    .submit-button {
        padding: 0.8rem 1.5rem;
        font-size: 1rem;
    }
}

.pastries-link {
    margin: 2rem auto;
    text-align: center;
    animation: slideInFromLeft var(--slide-time) ease-out forwards;
}

.pastries-button {
    display: inline-block;
    padding: 1rem 2rem;
    background-color: transparent;
    color: var(--accent);
    border: 2px solid var(--accent);
    border-radius: 4px;
    text-decoration: none;
    font-family: 'Cinzel', serif;
    font-weight: bold;
    transition: all 0.3s ease;
}

.pastries-button:hover {
    background-color: var(--secondary);
    color: var(--primary);
    border-color: var(--secondary);
}

.responsive-nav {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    background-color: var(--dark-transparent);
    backdrop-filter: blur(5px);
    z-index: 1000;
    padding: 1rem;
    height: 8%;
}

.nav-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}

.mobile-logo {
    font-size: 1.5rem;
    margin: 0;
    text-align: left;
    flex-grow: 1;
}

.hamburger-menu {
    display: block;
    background: none;
    border: none;
    cursor: pointer;
    width: 30px;
    height: 20px;
    position: relative;
    align-self: center;
    margin-left: auto; /* This will push the hamburger menu to the right */
}

.hamburger-menu span {
    display: block;
    width: 100%;
    height: 3px;
    background-color: var(--accent);
    position: absolute;
    left: 0;
    transition: all 0.3s ease;
}

.hamburger-menu span:nth-child(1) { top: 0; }
.hamburger-menu span:nth-child(2) { top: 50%; transform: translateY(-50%); }
.hamburger-menu span:nth-child(3) { bottom: 0; }

.nav-links {
    display: none;
    flex-direction: column;
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    background-color: var(--dark-transparent);
    backdrop-filter: blur(5px);
}

.nav-links.active {
    display: flex;
}

.nav-links a {
    color: var(--accent);
    text-decoration: none;
    padding: 1rem;
    text-align: center;
    border-top: 1px solid rgba(64, 224, 208, 0.2);
    transition: background-color 0.3s ease;
    display: block;
    width: 100%;
}

.nav-links a:hover {
    background-color: rgba(64, 224, 208, 0.1);
}

/* Add padding to the top of the container to prevent content from being hidden behind the navbar */
.app-container {
    min-height: 100vh;
    position: relative;
    overflow-x: hidden;
}

@media (min-width: 768px) {
    .responsive-nav {
        position: fixed;
        padding: 1rem;
        height: 8%;
    }

    .nav-header {
        position: absolute;
        left: 1rem;
        top: 50%;
        transform: translateY(-50%);
    }

    .hamburger-menu {
        display: none;
    }

    .nav-links {
        display: flex;
        flex-direction: row;
        position: static;
        background: none;
        backdrop-filter: none;
        justify-content: center;
        align-items: center;
        flex-grow: 1;
    }

    .nav-links a {
        border: none;
        margin: 0 10px;
        text-align: center;
        display: inline-block;
        width: auto;
    }

    .app-container {
        padding-top: 0;
    }
}

/* Mobile styles */
@media (max-width: 767px) {
    .app-container {
        padding-top: 70px;
    }
}

@media (max-width: 767px) {
    .mobile-logo {
        display: block;
    }
}

@media (min-width: 768px) {
    .mobile-logo {
        display: none; /* Hide on desktop */
    }

    .nav-header {
        display: none; /* Remove entire nav header on desktop */
    }
}

/* Add these styles after the catering-form-container styles */

.catering-packages {
    display: flex;
    gap: 2rem;
    justify-content: center;
    flex-wrap: wrap;
    margin: 2rem auto;
    max-width: 1000px;
}

.package {
    position: relative;
    background-color: var(--dark-transparent);
    backdrop-filter: blur(5px);
    border-radius: 8px;
    border: 2px solid var(--accent);
    padding: 1.5rem;
    min-width: 300px;
    flex: 1;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-height: 300px;
}

/* Remove hover transform effect */
.package:hover {
    transform: none;
}

.package h3 {
    color: var(--accent);
    margin-bottom: 1rem;
}

/* Update description to be static */
.package .description {
    position: static;
    display: block;
    background: none;
    border: none;
    box-shadow: none;
    padding: 0;
    margin: 1rem 0;
    color: var(--navy);
    width: 100%;
}

/* Remove description arrow and hover display */
.package .description::before {
    display: none;
}

.package:hover .description {
    display: block;
}

.package-quantity {
    margin-top: 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;
}

.quantity-button {
    background-color: var(--accent);
    color: var(--dark);
    border: none;
    border-radius: 4px;
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    font-size: 1.2rem;
    transition: background-color 0.3s ease;
}

.quantity-button:hover {
    background-color: var(--secondary);
}

.package-quantity input {
    width: 60px;
    padding: 0.5rem;
    border: 2px solid var(--accent);
    background-color: var(--dark);
    color: var(--primary);
    border-radius: 4px;
    font-family: 'Cinzel', serif;
    text-align: center;
    -moz-appearance: textfield;
}

.package-quantity input::-webkit-outer-spin-button,
.package-quantity input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.total-cost {
    background-color: var(--dark-transparent);
    backdrop-filter: blur(5px);
    border-radius: 8px;
    border: 2px solid var(--accent);
    padding: 1rem 2rem;
    margin: 1rem auto;
    font-size: 1.5rem;
    color: var(--accent);
    font-weight: bold;
    animation: fadeIn 0.3s ease-out;
}

.info-card {
    background-color: var(--dark-transparent);
    backdrop-filter: blur(5px);
    border-radius: 8px;
    padding: 20px;
    margin: 20px auto;
    max-width: 800px;
    text-align: center;
    border: 2px solid var(--accent);
}

.info-card h3 {
    margin-bottom: 10px;
    color: var(--accent);
}

.info-card p {
    color: var(--navy);
    line-height: 1.5;
}

.meat-choice {
    display: flex;
    gap: 1rem;
    justify-content: center;
    margin: 1rem 0;
}

.choice-button {
    background-color: transparent;
    color: var(--accent);
    border: 2px solid var(--accent);
    padding: 0.5rem 1rem;
    border-radius: 4px;
    cursor: pointer;
    transition: all 0.3s ease;
    font-family: 'Cinzel', serif;
    font-size: 0.9rem;
}

.choice-button:hover {
    background-color: var(--accent);
    color: var(--dark);
}

.choice-button.active {
    background-color: var(--accent);
    color: var(--dark);
}

.package-selector {
    background-color: var(--dark-transparent);
    backdrop-filter: blur(5px);
    border-radius: 8px;
    border: 2px solid var(--accent);
    padding: 2rem;
    margin: 2rem auto;
    max-width: 800px;
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    align-items: center;
}

.selector-group {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    width: 100%;
    max-width: 300px;
}

.selector-group label {
    color: var(--accent);
    font-size: 1.1rem;
}

.selector-group select {
    padding: 0.8rem;
    border: 2px solid var(--accent);
    background-color: var(--dark);
    color: var(--navy);
    border-radius: 4px;
    font-family: 'Cinzel', serif;
    width: 100%;
    cursor: pointer;
}

.add-to-order-button {
    background-color: var(--accent);
    color: var(--dark);
    border: none;
    padding: 1rem 2rem;
    border-radius: 4px;
    cursor: pointer;
    font-family: 'Cinzel', serif;
    font-size: 1.1rem;
    transition: background-color 0.3s ease;
}

.add-to-order-button:hover {
    background-color: var(--secondary);
    color: var(--primary);
    border-color: var(--secondary);
}

.selected-items {
    background-color: var(--dark-transparent);
    backdrop-filter: blur(5px);
    border-radius: 8px;
    border: 2px solid var(--accent);
    padding: 2rem;
    margin: 2rem auto;
    max-width: 800px;
}

.selected-items h3 {
    color: var(--accent);
    margin-bottom: 1.5rem;
    text-align: center;
}

.selected-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem;
    border-bottom: 1px solid var(--accent);
    color: var(--navy);
    gap: 2rem;
}

.selected-item:last-child {
    border-bottom: none;
}

.remove-item-button {
    background-color: transparent;
    color: var(--accent);
    border: 1px solid var(--accent);
    padding: 0.5rem 1rem;
    border-radius: 4px;
    cursor: pointer;
    font-family: 'Cinzel', serif;
    transition: all 0.3s ease;
}

.remove-item-button:hover {
    background-color: var(--accent);
    color: var(--dark);
}

.package-controls {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    align-items: center;
    margin-top: 1rem;
}

.meat-select {
    padding: 0.8rem;
    border: 2px solid var(--accent);
    background-color: var(--dark);
    color: var(--navy);
    border-radius: 4px;
    font-family: 'Cinzel', serif;
    width: 200px;
    cursor: pointer;
}

.add-to-order-button {
    width: 100%;
    max-width: 200px;
}

.add-to-order-button:disabled {
    opacity: 0.5;
    cursor: not-allowed;
}

/* Update package styles */
.package {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-height: 300px;
}

.package .description {
    margin: 1rem 0;
}

.checkbox-group {
    display: flex;
    align-items: center;
    gap: 0.5rem;
}

.checkbox-group input[type="checkbox"] {
    width: auto;
    margin-right: 0.5rem;
}

.transportation-fee {
    font-size: 1.2rem;
    color: var(--navy);
    margin: 0.5rem 0;
}

.final-total {
    font-size: 1.8rem;
    color: var(--accent);
    margin-top: 1rem;
    font-weight: bold;
}

.form-message.error {
    color: var(--accent);
    font-size: 0.9rem;
    margin-top: 0.5rem;
}

.delivery-address-section {
    border-top: 1px solid var(--accent);
    margin-top: 1rem;
    padding-top: 1rem;
    animation: fadeIn 0.3s ease-out;
}

.address-row {
    display: grid;
    grid-template-columns: 2fr 1fr 1fr;
    gap: 1rem;
}

@media (max-width: 768px) {
    .address-row {
        grid-template-columns: 1fr;
    }
}

/* Update existing animation */
@keyframes fadeIn {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

/* Add/update these styles */
.App {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    width: 100%;
    overflow-x: hidden;
}

header {
    text-align: center;
    padding: 1rem;
    width: 100%;
}

/* Ensure all content sections are properly centered */
.menu-section,
.desserts-section,
.catering-section {
    width: 100%;
    max-width: 100vw;
    margin: 0 auto;
    padding: 1rem;
    box-sizing: border-box;
}

/* Safari-specific fixes */
@supports (-webkit-touch-callout: none) {
    .container {
        position: relative;
        min-height: -webkit-fill-available;
    }
    
    /* Fix for Safari momentum scrolling */
    .menu-content,
    .desserts-content,
    .catering-content {
        -webkit-overflow-scrolling: touch;
    }
    
    /* Fix for Safari viewport height issues */
    html {
        height: -webkit-fill-available;
    }
}

/* Update nav link hover and active states */
.nav-links a:hover,
.nav-links a:active,
.nav-links a:focus {
    color: var(--orange) !important;
    border-color: var(--orange) !important;
}

/* Update mobile nav links */
@media screen and (max-width: 768px) {
    .nav-links a:hover {
        background-color: rgba(255, 107, 53, 0.1); /* Semi-transparent orange background */
        color: var(--orange);
    }

    .nav-links.active a:hover,
    .nav-links.active a:active,
    .nav-links.active a:focus {
        color: var(--orange) !important;
        background-color: rgba(255, 107, 53, 0.1); /* Semi-transparent orange background */
    }

    /* Add active state styling */
    .nav-links a.active {
        background-color: rgba(255, 107, 53, 0.1);
        color: var(--orange);
    }
}

.address-link {
    text-decoration: none;
    color: var(--primary);
    transition: color 0.3s ease;
}

.address-link:hover {
    color: var(--accent);
}

/* Keep the address styles consistent */
.address-link p {
    font-family: 'Roboto', Arial, sans-serif !important;
    text-transform: none !important;
    font-size: 1rem !important;
    letter-spacing: 0 !important;
    font-weight: 400 !important;
}

.background-image {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-size: contain;
    background-position: center center;
    background-repeat: repeat;
    opacity: 0.2;
    filter: grayscale(100%) contrast(120%);
    pointer-events: none;
    z-index: -1;
    max-width: 100vw;
    overflow: hidden;
}

/* Catering promo section */
.catering-promo {
    width: 100%;
    max-width: 800px;
    margin: 1rem auto 2rem;
    text-align: center;
    position: relative;
    animation: slideInFromRight var(--slide-time) ease-out forwards;
    animation-delay: 0.9s;
    opacity: 0;
}

.catering-button {
    display: inline-block;
    background-color: var(--yellow);
    color: #000;
    font-weight: bold;
    font-size: 1.4rem;
    padding: 1.2rem 2rem;
    border-radius: 8px;
    text-decoration: none;
    text-transform: uppercase;
    letter-spacing: 0.1em;
    transition: all 0.3s ease;
    box-shadow: 0 4px 12px rgba(255, 136, 0, 0.3);
    position: relative;
    overflow: hidden;
    border: 2px solid #ff7300;
    animation: pulse 2s infinite;
    z-index: 1;
}

.catering-button:hover {
    background-color: #ff7300;
    transform: translateY(-2px);
    box-shadow: 0 8px 16px rgba(255, 136, 0, 0.5);
    animation: none;
}

@keyframes pulse {
    0% {
        box-shadow: 0 4px 12px rgba(255, 136, 0, 0.3);
    }
    50% {
        box-shadow: 0 4px 20px rgba(255, 136, 0, 0.7);
    }
    100% {
        box-shadow: 0 4px 12px rgba(255, 136, 0, 0.3);
    }
}

.catering-subtitle {
    margin-top: 1rem;
    font-size: 1.1rem;
    color: var(--blue);
    font-style: italic;
}

.separator {
    width: 100%;
    max-width: 600px;
    height: 1px;
    background: linear-gradient(to right, transparent, var(--blue), transparent);
    margin: 0 auto;
    opacity: 0;
    animation: fadeIn var(--slide-time) ease-out forwards;
    animation-delay: 0.75s;
}

@media (max-width: 768px) {
    .catering-promo {
        margin: 1.5rem 1rem 3rem;
    }
    
    .catering-button {
        font-size: 1.2rem;
        padding: 1rem 1.5rem;
    }
}

@media (max-width: 480px) {
    .catering-button {
        font-size: 1rem;
        padding: 0.8rem 1.2rem;
        width: 100%;
    }
    
    .catering-promo {
        margin: 1rem 1rem 2.5rem;
    }
} 