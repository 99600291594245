.menu-viewer {
    position: relative;
    background-color: rgba(12, 12, 12, 0.4);
    backdrop-filter: blur(5px);
    border-radius: 8px;
    padding: 0.5rem;
    margin: 0.5rem auto 0;
    max-width: 1000px;
    width: 100%;
    height: calc(100vh - 220px);
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow: hidden;
    gap: 0.5rem;
}

.pdf-container {
    width: 100%;
    height: calc(100vh - 200px);
    overflow: hidden;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0.25rem;
    background-color: rgba(12, 12, 12, 0.4);
    backdrop-filter: blur(5px);
    border-radius: 8px;
    margin: 0.25rem auto;
    max-width: 1000px;
}

.pdf-page {
    width: 100% !important;
    height: 100% !important;
    background: transparent !important;
    margin: 0 auto;
    transition: none !important;
    object-fit: contain;
}

.page-controls {
    position: relative;
    width: 100%;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 2rem;
    padding: 0.25rem;
    left: auto;
    transform: none;
    max-width: 400px;
    font-family: 'Cinzel', serif;
}

.page-controls button {
    background-color: transparent;
    color: var(--blue);
    border: 2px solid var(--blue);
    padding: 0.5rem 1rem;
    border-radius: 4px;
    cursor: pointer;
    transition: all 0.3s ease;
    font-size: 1.2rem;
    min-width: 50px;
}

.page-controls button:hover:not(:disabled) {
    background-color: var(--blue);
    color: var(--gray);
}

.page-controls button:disabled {
    opacity: 0.5;
    cursor: not-allowed;
    border-color: rgba(64, 224, 208, 0.3);
}

.page-controls span {
    color: var(--blue);
    font-weight: bold;
    min-width: 120px;
    text-align: center;
}

.loading {
    text-align: center;
    padding: 20px;
    color: var(--blue);
    font-family: 'Cinzel', serif;
}

/* Update Document component styles */
.react-pdf__Document {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

/* Update mobile-specific scaling */
@media screen and (max-width: 768px) {
    .container {
        touch-action: manipulation; /* Allow pinch zoom and scrolling */
        height: 100vh;
        overflow-y: auto; /* Allow scrolling */
    }

    .pdf-container {
        background: none;
        backdrop-filter: none;
        border-radius: 0;
        height: calc(100vh - 120px);
        margin: 0.1rem 0.5rem;
        padding: 0;
        max-width: none;
        margin-top: 0; /* Remove top margin */
        touch-action: manipulation; /* Allow pinch zoom */
    }

    .pdf-page {
        padding: 0 !important;
        width: 100% !important;
        height: 100% !important;
        transform: none !important;
        object-fit: contain;
        touch-action: manipulation; /* Allow pinch zoom */
    }

    .react-pdf__Document {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: flex-start; /* Align to top */
        justify-content: center;
        touch-action: manipulation; /* Allow pinch zoom */
    }

    .page-controls {
        position: fixed;
        bottom: 10px;
        left: 50%;
        transform: translateX(-50%);
        background-color: rgba(12, 12, 12, 0.7);
        backdrop-filter: blur(5px);
        padding: 0.5rem;
        border-radius: 20px;
        width: 90%;
        max-width: 300px;
        z-index: 100;
        margin: 0;
    }

    .page-controls button {
        min-width: 40px;
        padding: 0.4rem 0.8rem;
    }

    .page-controls span {
        min-width: 80px;
        color: var(--blue);
    }

    header {
        margin-bottom: 0.25rem;
        padding: 0;
    }

    .subtitle {
        margin: 0.25rem 0;
    }
}

header {
    margin-bottom: 2rem;
    margin-top: 2rem;
}

h1 {
    margin-bottom: 0.5rem;
    letter-spacing: 0.2em;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
    color: var(--blue);
}

h2 {
    font-size: 2rem;
    letter-spacing: 0.5em;
    border-top: 2px solid var(--blue);
    border-bottom: 2px solid var(--blue);
    padding: 1rem 0;
    margin: 1rem auto;
    max-width: 600px;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
    color: var(--blue);
}

.greek-a {
    font-size: 1.1em;
    color: var(--yellow);
}

.menu-content {
    display: flex;
    flex-wrap: wrap;
    gap: 30px;
    justify-content: space-between;
    margin-top: 3rem;
}

.menu-section {
    background-color: rgba(0, 0, 0, 0.7);
    border-radius: 8px;
    padding: 20px;
    margin-bottom: 30px;
}

.half-width {
    width: calc(50% - 15px);
}

.full-width {
    width: 100%;
}

.menu-section h3 {
    color: var(--blue);
    font-size: 1.8rem;
    margin-bottom: 20px;
    text-align: center;
    text-transform: uppercase;
}

.section-note {
    text-align: center;
    font-style: italic;
    margin-bottom: 20px;
    color: var(--primary);
}

.menu-items {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.menu-item {
    border-bottom: 1px solid rgba(0, 128, 128, 0.3);
    padding-bottom: 15px;
}

.item-header {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    margin-bottom: 5px;
}

.item-header h4 {
    font-size: 1.2rem;
    margin: 0;
    color: var(--primary);
}

.price {
    color: var(--primary);
    font-weight: bold;
    margin-right: 39px;
}

.description {
    color: var(--primary);
    font-size: 0.9rem;
    margin: 0;
    line-height: 1.4;
}

/* Mobile Responsiveness */
@media (max-width: 768px) {
    .menu-container {
        padding: 0.5rem;
        margin-top: 0;
    }

    .menu-section {
        padding: 1rem;
        margin: 0.5rem 0;
        width: 100%;
        overflow: hidden; /* Prevent overflow */
    }

    /* Fix header alignment */
    .menu-section h3 {
        font-size: 1.6rem;
        margin-bottom: 1rem;
        text-align: center;
    }

    /* Adjust menu notes spacing */
    .menu-notes {
        margin: 0.5rem 0 1rem;
        padding: 0 0.5rem;
    }

    .menu-notes p {
        font-size: 0.9rem;
        margin: 0.25rem 0;
        line-height: 1.4;
    }

    /* Fix price header alignment */
    .price-header {
        display: flex !important;
        justify-content: flex-end !important;
        padding-right: 20px !important; /* Increase padding */
        margin: 0.5rem 0;
        gap: 0;
        width: 100%;
    }

    .price-header span {
        width: 80px; /* Slightly wider for better spacing */
        text-align: right !important;
        font-size: 1rem;
        padding: 0;
        margin-left: 0.5rem;
    }

    /* Fix price columns alignment */
    .price-columns {
        display: flex !important;
        justify-content: flex-end !important;
        padding-right: 20px !important; /* Match header padding */
        width: 100%;
        gap: 0;
    }

    .price {
        width: 80px; /* Match header width */
        text-align: right !important;
        margin: 0;
        padding: 0;
        margin-left: 0.5rem;
    }

    /* Special handling for three-column prices */
    .price-header.three-columns,
    .price-columns.three-columns {
        padding-right: 20px !important; /* Match other paddings */
    }

    .price-header.three-columns span,
    .price-columns.three-columns .price {
        width: 60px; /* Adjust for three columns */
        min-width: unset;
    }

    /* Fix menu item layout */
    .menu-item {
        padding: 0.75rem 0;
        border-bottom: 1px solid rgba(64, 224, 208, 0.2);
    }

    /* Fix item header layout */
    .item-header {
        display: flex;
        flex-direction: row !important;
        justify-content: space-between !important;
        align-items: flex-start;
        width: 100%;
        margin-bottom: 0.75rem;
    }

    .item-header h4 {
        font-size: 1.2rem;
        flex: 1;
        text-align: left;
        margin: 0;
        padding-right: 1rem; /* Increase spacing between title and prices */
        max-width: 65%; /* Prevent title from pushing prices too far right */
    }

    /* Fix three-column prices for drinks section */
    .price-header.three-columns {
        justify-content: flex-end;
        margin-right: 0;
    }

    .price-columns.three-columns {
        justify-content: flex-end;
    }

    /* Remove the grid layout that was causing alignment issues */
    .price-header,
    .price-columns {
        display: flex;
        grid-template-columns: unset;
    }

    /* Fix background and container */
    .menu-content {
        margin: 0;
        padding: 0.5rem;
        gap: 1rem;
    }

    /* Fix section notes */
    .section-note {
        font-size: 0.9rem;
        margin: 0.5rem 0;
        padding: 0 0.5rem;
        line-height: 1.4;
    }

    /* Fix price columns alignment */
    .price-columns {
        display: flex !important; /* Force flex display */
        justify-content: flex-end !important; /* Align to right */
        flex-direction: row !important; /* Force row layout */
        gap: 1rem;
        min-width: fit-content; /* Prevent wrapping */
    }

    .price {
        margin-right: 0;
        text-align: right !important; /* Force right alignment */
        width: auto; /* Allow natural width */
        min-width: 60px; /* Ensure minimum width for prices */
    }

    /* Fix price header alignment */
    .price-header {
        display: flex !important; /* Force flex display */
        justify-content: flex-end !important; /* Align to right */
        flex-direction: row !important; /* Force row layout */
        width: 100%;
        margin-bottom: 1rem;
        gap: 1rem;
    }

    .price-header span {
        text-align: right !important; /* Force right alignment */
        width: auto; /* Allow natural width */
        min-width: 60px; /* Ensure minimum width for headers */
    }

    /* Special handling for three-column prices (drinks section) */
    .price-header.three-columns,
    .price-columns.three-columns {
        padding-right: 20px !important;
        gap: 8px !important; /* Add space between columns */
        justify-content: flex-end !important;
    }

    .price-header.three-columns span,
    .price-columns.three-columns .price {
        width: 55px !important; /* Slightly wider for better spacing */
        min-width: unset;
        margin-left: 0 !important; /* Remove margin since we're using gap */
        font-size: 0.9rem !important;
        text-align: center !important; /* Center the text in each column */
    }

    /* Remove any conflicting styles */
    .price-columns.drinks {
        gap: 8px !important; /* Match the gap */
    }

    .price-columns.drinks .price {
        margin-right: 0 !important;
        text-align: center !important;
    }

    /* Ensure drink headers align with prices */
    .price-header.three-columns {
        gap: 8px !important;
    }
}

/* Safari-specific fixes */
@supports (-webkit-touch-callout: none) {
    @media (max-width: 768px) {
        .menu-container {
            -webkit-overflow-scrolling: touch;
        }

        .menu-section {
            transform: translateZ(0);
            -webkit-transform: translateZ(0);
        }

        .item-header {
            -webkit-transform: translateZ(0);
        }
    }
}

/* Style for ALL menu notes */
.menu-notes {
    text-align: center;
    margin-bottom: 20px;
    font-family: 'Roboto', Arial, sans-serif !important;
    text-transform: none !important;
    letter-spacing: 0 !important;
    font-weight: 400 !important;
    font-style: normal !important;
}

.menu-notes p {
    margin: 5px 0;
    color: var(--primary);
    font-family: 'Roboto', Arial, sans-serif !important;
    text-transform: none !important;
    letter-spacing: 0 !important;
    font-weight: 400 !important;
    font-size: 0.95rem;
    font-style: normal !important;
}

@media (max-width: 768px) {
    .menu-notes p {
        font-size: 0.85rem;
    }
}

.price-columns {
    display: flex;
    gap: 20px;
}

.menu-section.full-width {
    width: 100%;
}

.price-header {
    display: flex;
    justify-content: flex-end;
    gap: 20px;
    margin-bottom: 10px;
    padding-right: 20px;
}

.price-header span {
    color: var(--primary);
    font-weight: bold;
    width: 80px;
    text-align: center;
}

.menu-container {
    width: 100%;
    max-width: 1200px;
    margin: 80px auto 0;
    padding: 1rem;
    position: relative;
    color: white;
    text-align: center;
}

.menu-header {
    text-align: center;
    margin-bottom: 40px;
    width: 100%;
}

.menu-header h1 {
    font-size: 5rem;
    margin: 0;
    color: var(--blue);
    text-align: center;
    letter-spacing: 0.2em;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
}

.menu-header h2 {
    font-size: 2rem;
    margin: 10px 0;
    color: var(--blue);
    text-align: center;
    letter-spacing: 0.5em;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
}

.greek-a {
    font-size: 1.1em;
    color: var(--yellow);
}

.menu-content {
    display: flex;
    flex-wrap: wrap;
    gap: 30px;
    justify-content: space-between;
}

.menu-section {
    background-color: rgba(0, 0, 0, 0.7);
    border-radius: 8px;
    padding: 20px;
    margin-bottom: 30px;
}

.half-width {
    width: calc(50% - 15px);
}

.full-width {
    width: 100%;
}

.menu-section h3 {
    color: var(--blue);
    font-size: 1.8rem;
    margin-bottom: 20px;
    text-align: center;
    text-transform: uppercase;
}

.section-note {
    text-align: center;
    font-style: italic;
    margin-bottom: 20px;
    color: var(--primary);
}

.menu-items {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.menu-item {
    border-bottom: 1px solid rgba(0, 128, 128, 0.3);
    padding-bottom: 15px;
}

.item-header {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    margin-bottom: 5px;
}

.item-header h4 {
    font-size: 1.2rem;
    margin: 0;
    color: var(--primary);
}

.price {
    color: var(--primary);
    font-weight: bold;
    margin-right: 39px;
}

.description {
    color: var(--primary);
    font-size: 0.9rem;
    margin: 0;
    line-height: 1.4;
}

/* Mobile Responsiveness */
@media (max-width: 768px) {
    .menu-container {
        padding: 10px;
    }

    .half-width {
        width: 100%;
    }

    .menu-section {
        margin-bottom: 20px;
    }

    .menu-header h1 {
        font-size: 2.5rem;
    }

    .menu-header h2 {
        font-size: 1.4rem;
    }
}

.menu-notes {
    text-align: center;
    margin-bottom: 20px;
    font-family: 'Roboto', Arial, sans-serif !important;
    text-transform: none !important;
    letter-spacing: 0 !important;
    font-weight: 400 !important;
    font-style: normal !important;
}

.menu-notes p {
    margin: 5px 0;
    color: var(--primary);
    font-family: 'Roboto', Arial, sans-serif !important;
    text-transform: none !important;
    letter-spacing: 0 !important;
    font-weight: 400 !important;
    font-size: 0.95rem;
    font-style: normal !important;
}

@media (max-width: 768px) {
    .menu-notes p {
        font-size: 0.85rem;
    }
}

.price-columns {
    display: flex;
    gap: 20px;
}

.menu-section.full-width {
    width: 100%;
}

.price-header {
    display: flex;
    justify-content: flex-end;
    gap: 20px;
    margin-bottom: 10px;
    padding-right: 20px;
}

.price-header span {
    color: var(--primary);
    font-weight: bold;
    width: 80px;
    text-align: center;
}

.subtitle {
    margin-bottom: 2rem;
}

.special-note p {
    color: var(--primary);
}

.price-columns.three-columns {
    display: flex;
    gap: 20px;
    justify-content: flex-end;
}

.price-columns.three-columns .price {
    width: 60px;
    text-align: center;
}

.price-header.three-columns {
    display: flex;
    gap: 20px;
    justify-content: flex-end;
    margin-right: 39px;
}

.price-header.three-columns span {
    width: 60px;
    text-align: center;
}

.dessert-link {
    color: var(--yellow);
    text-decoration: none;
    transition: color 0.3s ease;
}

.dessert-link:hover {
    color: var(--blue);
}

/* Add Cinzel font to menu elements - REMOVE menu-notes from this list */
.menu-section h3,
.menu-item h4,
.price-header span,
.price-columns span,
.menu-item p,
.special-note p,
.loading,
.page-controls span,
.page-controls button {
    font-family: 'Cinzel', serif !important;
}

/* Animation classes */
.slide-in-left {
    opacity: 0;
    transform: translateX(-100px);
    transition: all 0.8s ease-out;
}

.slide-in-right {
    opacity: 0;
    transform: translateX(100px);
    transition: all 0.8s ease-out;
}

/* Animation trigger class that will be added by Intersection Observer */
.slide-in-left.visible,
.slide-in-right.visible {
    opacity: 1;
    transform: translateX(0);
}

/* Add stagger effect */
.menu-section {
    transition-delay: calc(var(--order) * 0.1s);
}

.global-notes {
    background-color: rgba(0, 0, 0, 0.5);
    padding: 15px;
    border-radius: 8px;
    margin-bottom: 30px;
    border: 1px solid var(--blue);
}

.global-notes p {
    color: var(--primary);
    margin: 5px 0;
    font-size: 0.95rem;
    font-family: 'Roboto', Arial, sans-serif !important;
    text-transform: none !important;
    letter-spacing: 0 !important;
    font-weight: 400 !important;
}

@media (max-width: 768px) {
    .global-notes {
        padding: 10px;
        margin-bottom: 20px;
    }
    
    .global-notes p {
        font-size: 0.85rem;
    }
}

/* Add these styles for drink menu alignment */
.menu-section.drinks {
    padding: 1rem;
}

.drink-sizes {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 0.5rem;
    text-align: center;
    margin: 1rem 0;
}

.drink-item {
    display: grid;
    grid-template-columns: 1fr auto;
    align-items: center;
    gap: 1rem;
    padding: 0.5rem 0;
}

@media (max-width: 768px) {
    .price-header {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        text-align: center;
        margin-bottom: 1rem;
    }

    .price-columns {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap: 0.5rem;
        text-align: center;
    }

    .menu-item .item-header {
        flex-direction: column;
        gap: 0.5rem;
    }

    .menu-item .price {
        margin-right: 0;
        text-align: center;
    }
}

/* Update text colors for menu items */
.menu-item .description,
.menu-notes p,
.menu-item h4,
.price,
.price-header span,
.special-note p,
.menu-item p {
    color: var(--primary);
}

/* Ensure consistent color in mobile view */
@media (max-width: 768px) {
    .menu-item .description,
    .menu-notes p,
    .menu-item h4,
    .price,
    .price-header span,
    .menu-section h3,
    .special-note p,
    .section-note,
    .menu-item p,
    .protein-selection {  /* Add protein selection */
        color: var(--primary);
    }
}

/* Add specific margin for drinks section prices */
.price-columns.drinks .price {
    margin-right: 24px;
}

/* Keep the default margin for other sections */
.price {
    color: var(--primary);
    font-weight: bold;
    margin-right: 39px;
}

/* Add these styles for meat selection dropdowns */
.meat-select,
.meat-select option {
    color: var(--primary) !important;
}

/* Update mobile styles to ensure consistent colors */
@media (max-width: 768px) {
    .menu-item .description,
    .menu-notes p,
    .menu-item h4,
    .price,
    .price-header span,
    .menu-section h3,
    .special-note p,
    .section-note,
    .menu-item p,
    .protein-selection,
    .meat-select,
    .meat-select option {
        color: var(--primary) !important;
    }
}

/* Ensure dropdown text is visible on all browsers */
select.meat-select option {
    background-color: var(--dark) !important;
    color: var(--primary) !important;
}

/* Add mobile overrides with higher specificity */
@media screen and (max-width: 768px) {
    .menu-container header h1 {
        letter-spacing: 0.05em !important;
    }
}

@media screen and (max-width: 400px) {
    .menu-container header h1,
    .menu-container .mobile-logo {
        letter-spacing: 0.02em !important;
        white-space: nowrap !important;
        width: auto !important;
    }

    .menu-container header h2 {
        font-size: 0.9rem !important;
        letter-spacing: 0.1em !important;
    }

    .menu-container header h3 {
        font-size: 0.8rem !important;
    }

    .menu-container .greek-a {
        font-size: 0.9em !important;
    }
}

/* Menu items with images */
.menu-item.with-image {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 2rem;
    padding: 1rem;
    border-radius: 8px;
    transition: all 0.3s ease;
    background-color: rgba(0, 0, 0, 0.2);
    margin-bottom: 1.5rem;
}

.menu-item.with-image:hover {
    background-color: rgba(0, 0, 0, 0.4);
    transform: translateY(-3px);
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2);
}

.item-content {
    flex: 1;
}

.item-image-container {
    width: 200px;
    height: 200px;
    border-radius: 8px;
    overflow: hidden;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
    border: 2px solid var(--accent);
    transition: all 0.3s ease;
    flex-shrink: 0;
}

.menu-item.with-image:hover .item-image-container {
    transform: scale(1.05);
    border-color: var(--orange);
}

.menu-item-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: all 0.3s ease;
}

.menu-item.with-image:hover .menu-item-image {
    transform: scale(1.1);
}

/* Ensure price headers align properly with price columns */
.price-header {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 1rem;
    padding-right: 1rem;
}

.price-header span {
    width: 110px;
    text-align: center;
    font-weight: bold;
    color: var(--accent);
}

.price-columns {
    display: flex;
    justify-content: flex-end;
    gap: 1rem;
}

.price-columns span {
    width: 110px;
    text-align: center;
}

@media (max-width: 768px) {
    .menu-item.with-image {
        flex-direction: column;
        gap: 1rem;
        padding: 1rem;
        align-items: center;
    }

    .item-content {
        width: 100%;
        text-align: center;
    }

    .item-image-container {
        width: 160px;
        height: 160px;
        margin-top: 0.5rem;
    }
    
    .price-header span,
    .price-columns span {
        width: 80px;
    }
}

/* Featured Items Gallery */
.featured-items {
    margin: 2rem auto 3rem;
    text-align: center;
    max-width: 100%;
    overflow: hidden;
    padding: 1.5rem;
    background-color: rgba(0, 0, 0, 0.4);
    border-radius: 12px;
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2);
    position: relative;
}

.featured-items h3 {
    color: var(--accent);
    font-size: 2.2rem;
    margin-bottom: 1.5rem;
    text-transform: uppercase;
    letter-spacing: 0.1em;
    text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.5);
}

.featured-gallery {
    display: flex;
    gap: 2rem;
    animation: autoScroll 30s linear infinite;
    scroll-behavior: smooth;
}

.featured-gallery:hover {
    animation-play-state: paused;
}

@media (max-width: 768px) {
    .featured-gallery {
        gap: 1rem;
        animation: autoScroll 25s linear infinite;
    }
}

/* Auto-scrolling animation for the gallery */
@keyframes autoScroll {
    0% {
        transform: translateX(0);
    }
    100% {
        transform: translateX(calc(-250px * 5 - 2rem * 5)); /* Width of items + gaps for 5 items */
    }
}

.featured-gallery::-webkit-scrollbar {
    display: none; /* Hide scrollbar for Chrome/Safari/Opera */
}

.featured-item {
    flex: 0 0 auto;
    text-align: center;
    transition: all 0.3s ease;
    padding: 0.75rem;
    border-radius: 10px;
    min-width: 250px;
}

.featured-item:hover {
    transform: translateY(-5px) scale(1.02);
}

.featured-image-container {
    width: 240px;
    height: 240px;
    border-radius: 12px;
    overflow: hidden;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.4);
    border: 3px solid var(--accent);
    transition: all 0.3s ease;
    margin-bottom: 0.75rem;
}

.featured-item:hover .featured-image-container {
    border-color: var(--orange);
    transform: scale(1.05);
}

.featured-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: all 0.3s ease;
}

.featured-item:hover .featured-image {
    transform: scale(1.1);
}

.featured-item h4 {
    color: var(--blue);
    font-size: 1.4rem;
    margin-top: 0.5rem;
    font-weight: 600;
    text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.3);
}

/* Clone the images to ensure continuous scrolling */
.featured-gallery::after {
    content: "";
    display: block;
    flex: 0 0 auto;
    min-width: calc(250px * 5 + 2rem * 5); /* Same as width of all images + gaps */
}

@media (max-width: 768px) {
    .featured-items {
        margin: 1rem auto 2rem;
        padding: 1rem 0.5rem;
    }
    
    .featured-items h3 {
        font-size: 1.8rem;
        margin-bottom: 1rem;
    }
    
    .featured-image-container {
        width: 180px;
        height: 180px;
    }
    
    .featured-item {
        min-width: 180px;
    }
    
    .featured-item h4 {
        font-size: 1.1rem;
    }
    
    /* Adjust clone width for mobile */
    .featured-gallery::after {
        min-width: calc(180px * 5 + 1rem * 5);
    }
    
    @keyframes autoScroll {
        0% {
            transform: translateX(0);
        }
        100% {
            transform: translateX(calc(-180px * 5 - 1rem * 5)); /* Width of items + gaps on mobile */
        }
    }
}

/* Section Divider */
.section-divider {
    display: flex;
    align-items: center;
    margin: 2.5rem auto 3.5rem;
    max-width: 900px;
    padding: 0 1rem;
}

.divider-line {
    flex-grow: 1;
    height: 3px;
    background: linear-gradient(to right, rgba(64, 180, 184, 0.1), rgba(64, 180, 184, 0.7), rgba(64, 180, 184, 0.1));
    box-shadow: 0 0 5px rgba(64, 180, 184, 0.4);
    border-radius: 2px;
}

.divider-icon {
    padding: 0 2.5rem;
}

.divider-icon .greek-a {
    font-size: 3.5rem;
    color: var(--yellow);
    text-shadow: 0 0 5px rgba(255, 136, 0, 0.5);
    animation: pulse 3s infinite ease-in-out;
}

@keyframes pulse {
    0% {
        transform: scale(1);
        opacity: 1;
    }
    50% {
        transform: scale(1.1);
        opacity: 0.8;
    }
    100% {
        transform: scale(1);
        opacity: 1;
    }
}

@media (max-width: 768px) {
    .section-divider {
        margin: 2rem auto 3rem;
    }
    
    .divider-line {
        height: 3px;
    }
    
    .divider-icon {
        padding: 0 1.5rem;
    }
    
    .divider-icon .greek-a {
        font-size: 2.5rem;
    }
} 