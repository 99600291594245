:root {
    --orange: #ff6b35; /* or whatever orange color value you're using */
}

.menu-viewer {
    position: relative;
    background-color: rgba(12, 12, 12, 0.4);
    backdrop-filter: blur(5px);
    border-radius: 8px;
    padding: 2rem;
    margin: 1rem auto 0;
    max-width: 1000px;
    width: 100%;
    height: calc(100vh - 400px); /* Slightly higher to account for the Phan's Pastries link */
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow: hidden;
}

.pdf-container {
    width: 100%;
    height: calc(100vh - 300px); /* Reduced from 350px */
    overflow: hidden;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0.25rem; /* Reduced from 1rem */
    background-color: rgba(12, 12, 12, 0.4);
    backdrop-filter: blur(5px);
    border-radius: 8px;
    margin: 0.25rem auto; /* Reduced from 0.5rem */
    max-width: 650px;
}

.react-pdf__Document {
    display: flex;
    justify-content: center;
    align-items: center;
    max-height: 100%;
}

.react-pdf__Page {
    display: flex;
    justify-content: center;
    align-items: center;
    max-height: 100%;
}

.pdf-page {
    max-height: 100%;
    width: auto !important;
    height: auto !important;
    max-width: 100%;
    object-fit: contain;
    background: transparent !important;
}

/* Inherit other styles from Menu.css */

header {
    margin-bottom: 0.25rem;
    padding-bottom: 0;
}

.subtitle {
    margin: 0.25rem 0;
}

.pastries-link {
    margin: 0.25rem 0; /* Reduced from 0.5rem */
}

.dessert-navigation {
    display: flex;
    gap: 1.5rem; /* Reduced from 2rem */
    margin: 0.25rem 0; /* Reduced from 0.5rem */
    justify-content: center;
}

.dessert-nav-button {
    background-color: transparent;
    color: var(--blue);
    border: 2px solid var(--blue);
    padding: 0.8rem 2rem;
    border-radius: 4px;
    cursor: pointer;
    transition: all 0.3s ease;
    font-size: 1.2rem;
    font-family: 'Cinzel', serif;
    opacity: 0.8;
}

.dessert-nav-button:hover {
    background-color: var(--orange);
    color: var(--dark);
    border-color: var(--orange);
    opacity: 1;
}

.dessert-nav-button.active {
    background-color: var(--orange);
    color: var(--dark);
    border-color: var(--orange);
    opacity: 1;
}

.pastries-button:hover {
    background-color: var(--orange);
    color: black;
    border-color: var(--orange);
}

@media screen and (max-width: 768px) {
    header {
        margin-bottom: 0.25rem;
        padding: 0;
    }

    .subtitle {
        margin: 0.25rem 0;
    }

    .container {
        touch-action: manipulation;
        height: auto; /* Changed from 100vh to auto to allow content to extend */
        overflow-y: auto;
        min-height: 100vh; /* Ensure it's at least full viewport height */
        padding-bottom: 80px;
    }

    .pdf-container {
        background: none;
        backdrop-filter: none;
        border-radius: 0;
        height: calc(100vh - 160px); /* Reduced space taken by header */
        min-height: 600px; /* Ensure minimum height */
        margin: 0.1rem 0.5rem;
        padding: 0;
        max-width: none;
        margin-top: 0;
        touch-action: manipulation;
    }

    .pdf-page {
        padding: 0 !important;
        width: 100% !important;
        height: auto !important; /* Allow height to adjust to content */
        min-height: 100% !important;
        transform: none !important;
        object-fit: contain;
        touch-action: manipulation;
    }

    .react-pdf__Document {
        width: 100%;
        height: auto; /* Allow height to adjust to content */
        min-height: 100%;
        display: flex;
        align-items: flex-start;
        justify-content: center;
        touch-action: manipulation;
    }

    .dessert-navigation {
        margin: 0.1rem 0;
        gap: 0.75rem; /* Reduced from 1rem */
        padding: 0 0.5rem;
    }

    .pastries-link {
        margin: 0.1rem 0;
        padding: 0 0.5rem;
    }

    .page-controls {
        position: fixed;
        bottom: 20px;
        left: 50%;
        transform: translateX(-50%);
        background-color: rgba(12, 12, 12, 0.8);
        backdrop-filter: blur(5px);
        padding: 0.75rem;
        border-radius: 25px;
        width: 90%;
        max-width: 300px;
        z-index: 1000;
        margin: 0;
        display: flex;
        justify-content: space-between;
        align-items: center;
        box-shadow: 0 2px 10px rgba(0, 0, 0, 0.3);
    }

    .page-controls button {
        background-color: transparent;
        color: white;
        border: none;
        font-size: 1.5rem;
        padding: 0.5rem 1rem;
        cursor: pointer;
    }

    .page-controls span {
        color: white;
        font-size: 1rem;
    }
}

/* Add Cinzel font to dessert page elements */
header h1,
header h2,
header h3,
.dessert-nav-button,
.pastries-button,
.page-controls span,
.page-controls button,
.loading,
.error {
    font-family: 'Cinzel', serif !important;
} 