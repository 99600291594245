.info-card {
    background-color: rgba(12, 12, 12, 0.4);
    backdrop-filter: blur(5px);
    border-radius: 8px;
    padding: 1rem;
    margin: 1rem auto;
    max-width: 1000px;
    text-align: center;
    border: 1px solid rgba(0, 0, 0, 0.3);
}

.selected-items {
    background-color: rgba(12, 12, 12, 0.4);
    backdrop-filter: blur(5px);
    border-radius: 8px;
    padding: 1rem;
    margin: 1rem auto;
    max-width: 1000px;
    border: 1px solid rgba(0, 0, 0, 0.3);
}

.total-cost {
    background-color: rgba(12, 12, 12, 0.4);
    backdrop-filter: blur(5px);
    border-radius: 8px;
    padding: 1rem;
    margin: 1rem auto;
    max-width: 1000px;
    border: 1px solid rgba(0, 0, 0, 0.3);
}

.catering-form-container {
    background-color: rgba(12, 12, 12, 0.4);
    backdrop-filter: blur(5px);
    border-radius: 8px;
    padding: 1rem;
    margin: 1rem auto;
    max-width: 1000px;
    border: 1px solid rgba(0, 0, 0, 0.3);
}

/* Keep the teal border only for package cards */
.package {
    border: 2px solid var(--blue);
    border-radius: 8px;
    padding: 1rem;
    margin: 1rem;
    background-color: rgba(12, 12, 12, 0.4);
    backdrop-filter: blur(5px);
}

.selected-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0.5rem;
    margin: 0.5rem 0;
    background-color: rgba(12, 12, 12, 0.6);
    border-radius: 4px;
    border: 1px solid rgba(0, 0, 0, 0.3);
}

.form-group {
    margin-bottom: 1.5rem;
    background-color: rgba(12, 12, 12, 0.7);
    padding: 1.2rem;
    border-radius: 6px;
    border: 1px solid rgba(64, 224, 208, 0.3);
}

.form-group label {
    display: block;
    margin-bottom: 0.6rem;
    font-size: 1.1rem;
    font-weight: bold;
    color: var(--blue);
    text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.5);
}

.catering-form input,
.catering-form select,
.catering-form textarea {
    width: 100%;
    padding: 1rem;
    border: 2px solid var(--accent);
    background-color: rgba(0, 0, 0, 0.5);
    color: white;
    border-radius: 4px;
    font-family: 'Roboto', sans-serif;
    transition: all 0.3s ease;
    font-size: 1.1rem;
}

.catering-form input::placeholder,
.catering-form textarea::placeholder {
    color: rgba(255, 255, 255, 0.7);
    opacity: 1;
    font-size: 1rem;
}

.catering-form select {
    appearance: none;
    background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='none' stroke='%2340e0d0' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3e%3cpolyline points='6 9 12 15 18 9'%3e%3c/polyline%3e%3c/svg%3e");
    background-repeat: no-repeat;
    background-position: right 1rem center;
    background-size: 1.2em;
    padding-right: 3rem;
    font-size: 1.1rem;
    color: white;
}

.catering-form select option {
    background-color: var(--dark);
    color: white;
    font-size: 1.1rem;
}

.catering-form input:focus,
.catering-form select:focus,
.catering-form textarea:focus {
    outline: none;
    border-color: var(--blue);
    box-shadow: 0 0 8px rgba(64, 224, 208, 0.5);
    background-color: rgba(0, 0, 0, 0.7);
}

.delivery-address-section {
    background-color: rgba(12, 12, 12, 0.4);
    border-radius: 8px;
    padding: 1rem;
    margin: 1rem 0;
    border: 1px solid rgba(0, 0, 0, 0.3);
}

@media screen and (max-width: 768px) {
    .info-card,
    .selected-items,
    .total-cost,
    .catering-form-container {
        margin: 0.5rem;
        padding: 0.75rem;
    }

    .package {
        margin: 0.5rem;
        padding: 0.75rem;
    }
}

.custom-meat-choices {
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin: 15px 0;
}

.custom-meat-choice {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    background-color: rgba(0, 0, 0, 0.2);
    border-radius: 4px;
}

.custom-meat-choice label {
    font-size: 1.15rem;
    font-weight: bold;
    color: white;
    text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.4);
}

.custom-quantity-controls {
    display: flex;
    align-items: center;
    gap: 10px;
}

.custom-meat-choice input {
    width: 60px;
    padding: 6px;
    border: 2px solid var(--accent);
    border-radius: 4px;
    text-align: center;
    background-color: rgba(0, 0, 0, 0.5);
    color: white;
    font-size: 1.1rem;
    font-weight: bold;
}

.custom-meat-choice .quantity-button {
    width: 34px;
    height: 34px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--blue);
    border: none;
    border-radius: 4px;
    color: black;
    font-size: 1.3em;
    cursor: pointer;
    transition: all 0.3s ease;
    font-weight: bold;
}

.custom-meat-choice .quantity-button:hover {
    background-color: #ff8800;
    transform: translateY(-1px);
    box-shadow: 0 3px 8px rgba(255, 136, 0, 0.5);
}

.custom-meat-choice .quantity-button:disabled {
    background-color: #666;
    cursor: not-allowed;
}

.total-count {
    margin-top: 20px;
    text-align: right;
    font-weight: bold;
    padding: 12px;
    background-color: rgba(0, 0, 0, 0.3);
    border-radius: 6px;
    font-size: 1.1rem;
    color: white;
    border: 1px solid rgba(64, 224, 208, 0.3);
}

.custom-breakdown {
    font-size: 0.9em;
    color: #ccc;
    margin-left: 20px;
    margin-top: 5px;
}

/* Remove arrows from number input */
.custom-meat-choice input::-webkit-outer-spin-button,
.custom-meat-choice input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.custom-meat-choice input[type=number] {
    -moz-appearance: textfield;
}

.total-count-warning {
    color: var(--yellow);
    font-size: 1rem;
    margin-top: 0.5rem;
    font-style: italic;
    font-weight: bold;
}

/* Keep headers blue, change content text to light blue-gray */
.package h3 {
    color: var(--accent);
    margin-bottom: 1rem;
    font-size: 1.7rem;
}

.package .description {
    position: static;
    display: block;
    background: none;
    border: none;
    box-shadow: none;
    padding: 0;
    margin: 1rem 0;
    color: white;
    width: 100%;
    font-size: 1.2rem;
    font-weight: normal;
    line-height: 1.6;
}

.package .description.larger-text {
    font-size: 1.3rem;
    line-height: 1.7;
    color: white;
    text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.5);
}

.catering-form-container h3,
.selected-items h3 {
    color: var(--blue);
}

/* Update all content text to light blue-gray */
.info-card p,
.package p.description,
.selected-item span,
.custom-meat-choice label,
.total-count,
.custom-breakdown,
.form-group label,
.form-message,
.total-cost,
.transportation-fee,
.final-total {
    color: var(--primary);
}

/* Keep warning messages in yellow */
.total-count-warning {
    color: var(--yellow);
}

/* Keep error messages in their current color */
.form-message.error {
    color: #ff0000;
}

.form-message.success {
    color: #00ff00;
}

/* Update form input text colors and placeholders */
.form-group input,
.form-group textarea,
.form-group select {
    color: var(--primary);
}

/* Add placeholder styles for all browsers */
.form-group input::placeholder,
.form-group textarea::placeholder {
    color: var(--primary);
    opacity: 0.7;  /* Slightly dimmer for placeholder text */
}

/* Firefox */
.form-group input::-moz-placeholder,
.form-group textarea::-moz-placeholder {
    color: var(--primary);
    opacity: 0.7;
}

/* Chrome, Safari */
.form-group input::-webkit-input-placeholder,
.form-group textarea::-webkit-input-placeholder {
    color: var(--primary);
    opacity: 0.7;
}

/* Edge */
.form-group input::-ms-input-placeholder,
.form-group textarea::-ms-input-placeholder {
    color: var(--primary);
    opacity: 0.7;
}

/* Style select placeholder (first option) */
.form-group select option:first-child {
    color: var(--primary);
}

/* Style other select options */
.form-group select option {
    color: var(--primary);
    background-color: var(--dark);  /* Match your dark background */
}

/* Update checkbox label color */
.checkbox-group label {
    color: var(--primary);
}

/* Keep the custom breakdown slightly dimmer for hierarchy */
.custom-breakdown {
    color: rgba(184, 197, 201, 0.8); /* Slightly dimmer version of var(--primary) */
}

/* Ensure mobile view maintains consistent colors */
@media screen and (max-width: 768px) {
    .info-card p,
    .package p.description,
    .selected-item span,
    .custom-meat-choice label,
    .total-count,
    .form-group label,
    .total-cost,
    .transportation-fee,
    .final-total {
        color: var(--primary);
    }
}

/* Larger text class */
.larger-text {
    font-size: 1.3rem !important;
    line-height: 1.6 !important;
}

.highlight-text {
    font-size: 1.4rem !important;
    color: var(--yellow) !important;
    font-weight: bold;
    margin-top: 1rem;
    text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.5);
    letter-spacing: 0.03em;
}

/* Progress Tracker Styles */
.form-progress-tracker {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 2rem auto;
    max-width: 700px;
    padding: 1.5rem;
    background-color: rgba(12, 12, 12, 0.75);
    border-radius: 8px;
    position: relative;
    border: 1px solid rgba(64, 224, 208, 0.3);
}

.progress-step {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    z-index: 2;
    flex: 1;
    text-align: center;
}

.step-circle {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background-color: rgba(64, 224, 208, 0.2);
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: bold;
    margin-bottom: 10px;
    border: 2px solid var(--blue);
    color: var(--blue);
    transition: all 0.3s ease;
    font-size: 1.2rem;
}

.step-label {
    font-size: 1.1rem;
    color: var(--primary);
    transition: all 0.3s ease;
    font-weight: bold;
}

.progress-step.completed .step-circle {
    background-color: var(--blue);
    color: black;
    box-shadow: 0 0 10px rgba(64, 224, 208, 0.6);
}

.progress-step.completed .step-label {
    color: var(--blue);
}

.progress-bar {
    height: 3px;
    background-color: rgba(64, 224, 208, 0.3);
    flex-grow: 1;
    margin: 0 10px;
    position: relative;
    top: -20px;
    z-index: 1;
}

@media (max-width: 768px) {
    .form-progress-tracker {
        flex-direction: column;
        gap: 1rem;
    }
    
    .progress-bar {
        width: 3px;
        height: 30px;
        margin: 5px 0;
    }
    
    .progress-step {
        flex-direction: row;
        width: 100%;
        justify-content: flex-start;
        gap: 1rem;
    }
    
    .step-label {
        margin-bottom: 0;
    }
}

.submit-button {
    background-color: var(--blue);
    color: #000;
    border: 2px solid var(--blue);
    text-transform: uppercase;
    letter-spacing: 0.2em;
    font-weight: bold;
    padding: 1.2rem 2rem;
    border-radius: 6px;
    cursor: pointer;
    transition: all 0.3s ease;
    font-size: 1.2rem;
    margin-top: 1rem;
    box-shadow: 0 4px 12px rgba(64, 224, 208, 0.3);
    width: 100%;
}

.submit-button:hover {
    background-color: #ff8800;
    border-color: #ff7300;
    transform: translateY(-2px);
    box-shadow: 0 6px 20px rgba(255, 136, 0, 0.6);
}

.save-progress-button {
    display: block;
    width: 100%;
    padding: 0.8rem;
    margin: 1rem 0;
    background-color: rgba(255, 136, 0, 0.7);
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-weight: bold;
    transition: all 0.3s ease;
    font-size: 1rem;
    text-transform: uppercase;
    letter-spacing: 0.03em;
}

.save-progress-button:hover {
    background-color: rgba(255, 136, 0, 0.9);
    transform: translateY(-2px);
    box-shadow: 0 6px 12px rgba(255, 136, 0, 0.3);
}

.form-message {
    margin-top: 0.5rem;
    font-size: 0.9em;
    color: var(--primary);
}

.form-completion-message {
    background-color: rgba(64, 224, 208, 0.15);
    border-left: 4px solid var(--blue);
    padding: 1.5rem;
    margin-bottom: 2rem;
    border-radius: 6px;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.2);
}

.form-completion-message p {
    margin-bottom: 0;
    color: white;
}

.form-completion-message .larger-text {
    font-size: 1.3rem !important;
    line-height: 1.6 !important;
    color: #ff8800 !important;
    text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.5);
}

.form-message.success {
    color: #00ff00;
}

.meat-select {
    padding: 1rem;
    border: 2px solid var(--accent);
    background-color: var(--dark);
    color: var(--navy);
    border-radius: 4px;
    font-family: 'Cinzel', serif;
    width: 240px;
    cursor: pointer;
    font-size: 1.2rem;
}

.package-quantity {
    margin-top: 1.2rem;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.8rem;
}

.quantity-button {
    background-color: var(--accent);
    color: var(--dark);
    border: none;
    border-radius: 4px;
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    font-size: 1.5rem;
    transition: background-color 0.3s ease;
}

.quantity-button:hover {
    background-color: #ff8800;
    transform: translateY(-1px);
    box-shadow: 0 3px 10px rgba(255, 136, 0, 0.5);
}

.package-quantity input {
    width: 80px;
    padding: 0.7rem;
    border: 2px solid var(--accent);
    background-color: var(--dark);
    color: var(--primary);
    border-radius: 4px;
    font-family: 'Cinzel', serif;
    text-align: center;
    -moz-appearance: textfield;
    font-size: 1.3rem;
    font-weight: bold;
}

.add-to-order-button {
    background-color: var(--accent);
    color: var(--dark);
    border: none;
    padding: 0.9rem 1.5rem;
    border-radius: 6px;
    cursor: pointer;
    font-family: 'Cinzel', serif;
    font-size: 1.2rem;
    transition: all 0.3s ease;
    width: 100%;
    max-width: 240px;
    margin-top: 1rem;
    font-weight: bold;
    box-shadow: 0 3px 8px rgba(64, 224, 208, 0.3);
}

.add-to-order-button:hover {
    background-color: #ff8800;
    color: black;
    transform: translateY(-2px);
    box-shadow: 0 5px 15px rgba(255, 136, 0, 0.6);
} 