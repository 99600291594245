.footer {
  background-color: rgba(0, 0, 0, 0.98);
  backdrop-filter: blur(5px);
  color: var(--primary-text);
  padding: 2rem 0;
  margin-top: 3rem;
  width: 100%;
  border-top: 1px solid var(--accent-color);
}

.footer-content {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 2rem;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 2rem;
}

.footer h4 {
  color: var(--accent-color);
  margin-bottom: 1rem;
  font-size: 1.2rem;
}

.footer p {
  margin: 0.5rem 0;
}

.footer-attribution {
  text-align: center;
  align-self: center;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.footer-attribution a {
  color: var(--accent-color);
  text-decoration: none;
  transition: color 0.3s ease;
  font-weight: 500;
}

.footer-attribution a:hover span {
  opacity: 0.8;
}

.footer-attribution a span {
  text-decoration: underline;
}

.copyright {
  font-size: 0.9rem;
  opacity: 0.9;
}

@media (max-width: 768px) {
  .footer-content {
    grid-template-columns: 1fr;
    text-align: center;
  }
  
  .footer {
    padding: 2rem 1rem;
  }
} 